.bg {
  background-image: url("../Images/coding_bg.jpg");
  filter: brightness(20%);
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
}

.home-wrapper {
  height: 100vh;
  position: relative;
  /*overflow: hidden;*/
}

.home-content {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: brightness(20%); */
}

.home-description,
.home-description span {
text-align: left;
  color: white;
  z-index: 2;
  font-family: var(--bodyFont) !important;
  /*font-family: Helvetica;*/
  /*font-family: 'JetBrains Mono', monospace;*/
  /*font-family: 'Karla', sans-serif;*/
}

.home-description {
  /* background-color: grey; */
  display: block;
  /*height: 40vh;*/
  height: 6rem;

  /*width: 40vw;*/
  margin-left:auto;
  margin-right:auto;
  /*border: 2px solid yellow;*/
}

.home-description span {
  color: #e94560;
}

span#Singapore{
    background-image: linear-gradient(80deg,#ef3340, #f4f9ff);
    background-size: 100%;
    background-clip: border-box;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .home-description{
    width: 18rem;
    height: 20rem;
  }
}