.skills-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: var(--headerFont) !important;
}

.skills-wrapper {
  width: auto;
  /* border: 2px solid green; */
  overflow: scroll;
}

.skills-wrapper::-webkit-scrollbar {
  display: none;
}

.skills-title {
  display: block;
  color: white;
  text-align: left;
  text-transform: uppercase;
}

/* Make the job experiences animate up and down */
.tagCloud span{
  padding: 0.3em 0.3em;
}

.tagCloud span:nth-child(odd),
.jobWrapper div:nth-child(odd) {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.tagCloud span:nth-child(even),
.jobWrapper div:nth-child(even) {
  animation-name: floatingReverse;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.duration{
  font-style: italic;
}

.jobPos {
  color: var(--yellow);
}

.title2 {
  margin-top: 50px;
}

.jobWrapper {
  /*display: flex;*/
  font-size: 1.8rem;
}
.jobBox {
  margin-top: 2rem;
  margin-bottom:2rem;
  margin-left: auto;
  margin-right: auto;
}

#box-wrap{
  background-color: grey;
}
.ul-skills{
  display:flex;
}

.ul-skills li{
  padding: 20px;
  list-style: none;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 2px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes floatingReverse {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -2px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
