.contact-container {
  color: white;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  /* border: 2px solid yellow; */
  overflow-y: scroll;
}

.contact-content::-webkit-scrollbar {
  display: none;
}

.subtitle {
  color: #a09e9e;
  width: auto;
  font-size: 1.5rem;
}

form {
  display: flex;
  flex-direction: column;
}


/* Social media Icons */
.socialIcons {
  display: flex;
  flex-wrap: wrap;
}

.socialIcons i {
  margin-right: 1rem;
  /* border: 2px solid yellow; */
  font-size: 4rem;
  color:var(--defaultRed);
  transition: var(--transitionTime);

}

.socialIcons i:hover{
  color: var(--yellow);
  transition: var(--transitionTime);
}

.contactTextfield {
  background-color: var(--darkBlue);
  border: 2px solid var(--defaultRed);
  margin-top: 1em;
  /* height: 3em; */
  padding: 0 0.5em;
  width: 100%;
  color: white;
  border-radius: 10px;
  font-size: 1.5rem;
  transition: var(--transitionTime);
}

.contactTextfield::placeholder {
  color: rgb(156, 156, 156);
}

.contactTextfield:focus, .contactTextfield:active{
  border: 2px solid white;
}

.textarea {
  resize: vertical;
}

/* Submit your message */
.submit-btn{
  background-color: var(--darkBlue);
  border: 3px solid var(--defaultRed);
  width: 20rem;
  margin-top: 1rem;
  font-size: 1.3rem;
  transition: var(--transitionTime);
  color:white;
  display:flex;
  justify-content: center;

}

.submit-btn:hover{
  transition: var(--transitionTime);
  background-color: var(--yellow);
  cursor: pointer;
  color: black;
  font-weight: bold;
  border: 3px solid (--defaultRed)
}

.loadingIndicator{
  margin-left: 1em;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .socialIcons i {
    font-size:3em;
  }

  .subtitle{
    font-size: 1em;
  }

  .contactTextfield{
    font-size: 1.3rem;
    width: 17rem;
  }

  .submit-btn{
    width: 10rem;
    font-size: 1em;
  }
}