
.workWrapper {
  display: flex;
  color: white;
  font-size: 2em;
  width: 50rem;
  padding: 1em 1em ;
  border-left:2px solid var(--darkRed);
  margin-left: 1.2em;
}

/*a.projLink{
  text-decoration: none; color: var(--defaultRed);
  transition: all var(--transitionTime);
}*/


/* Fade in the underline decoration */
a.projLink {
	display: block;
  color: var(--defaultRed);
  text-decoration: none;
	position: relative;
	padding: 0.01em 0;
}

a.projLink:hover{
  transition: all var(--transitionTime);
  color:whitesmoke;
}

a.projLink::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.1em;
	background-color: var(--defaultRed);
	opacity: 0;
	transition: opacity 300ms, transform 300ms;
}

a.projLink:hover::after,
a.projLink:focus::after {
	opacity: 1;
	transform: translate3d(0, 0.05em, 0);
}

/*.projLink a:hover{
  transition: all var(--transitionTime);
  text-decoration: underline;
  color: white;
}*/


/* Project title */
.work-title {
  font-size: 4rem;
  line-height: 1em;
  font-family: var(--bodyFont);
  color: var(--defaultRed);
}

/* Project Desc */
.work-desc {
  font-size: 1.5rem;
  font-family: var(--bodyFont);
}

/* Source code button link */
a.buttonLink{
  border: none;
  font-size: 1rem;
  color:white;
  transition: var(--transitionTime);
  text-decoration: none;
  padding: 2px 8px;
  font-family: var(--codeFont);

}

.buttonLink:hover{
  cursor: pointer;
  transition: var(--transitionTime);
  box-sizing: border-box;
  color: var(--yellow);
  font-family: var(--codeFont);
}

.buttonLink i{
  margin-right: 10px;
}


.confidentialCodes{
  font-size: 1rem;
  font-family: var(--codeFont);
  color: white;
  outline: none;
  border:none;
  background:transparent;
}

.confidentialCodes:hover{
  color: var(--darkRed);
  cursor:default;

}

/* Tags */
.tagSpan{
  margin: 0px 10px;
  padding: 2px 10px;
  border-radius:1em;
  font-size: 1rem;
  font-family: var(--bodyFont);
  /*background-color: var(--defaultRed)*/
  color:whitesmoke;
  outline:none;
  border:none;
  background-color: var(--defaultRed);
  transition :var(--transitionTime);
}

.tagSpan:hover{
  cursor:pointer;
  color:black;
  background-color: white;
  transition :var(--transitionTime);
}

.collaborators{
  display:flex;
  padding: 2px 10px;
  border-radius:1em;
  font-size: 1rem;
  font-family: var(--bodyFont);
  color: whitesmoke;
  outline:none;
  border:none;
  transition :var(--transitionTime);
}

.collabHeading{
  margin-right: 10px;
}


.collaborators a{
  padding: 0, 20px;
  text-decoration: none;
  color: whitesmoke;
}

.collaborators a:hover{
  cursor:pointer;
  color: var(--defaultRed);
  transition :var(--transitionTime);
}

@media only screen and (max-width: 600px) and (min-width: 300px) {

  .collaborators{
    display: block;
  }
  .workWrapper {
    padding: .5em .5em ;
    margin-left: 0em;
    width: 15rem;
  }

  .work-title{
    font-size: 2rem;
    margin-bottom: 1em;
  }

  .work-desc{
    font-size: 1rem;
  }

  .buttonLink{
    font-size: .4em;
  }

  .tagSpan{
    font-size: .4em;
    margin: .1em .2em ;
  }

}