/*
Main font size : 2.5rem
*/

:root{
  --headerFont : 'Bebas Neue', cursive;
  --bodyFont :'Jost', sans-serif;
  --codeFont : 'Fira Code', monospace;
  --defaultRed: #e94560;
  --darkRed: #E82E4D;
  --yellow: #E8C55D;
  --green: #2EE851;
  --blue : #3A59E8;
  --darBlue: #0f3460;
  --transitionTime: 0.4s;

}

html,
* {
  margin: 0;
  padding: 0;
  font-family: var(--bodyFont);
  /* overflow: hidden; */
}

body {
  background-color: #090913;
}

.title {
  font-size: 3rem;
  font-family: var(--headerFont);
}

.title2 {
  font-size: 2.4rem;
  font-family: var(--headerFont);
}

.title3 {
  font-size: 1.8rem;
  font-family: var(--headerFont);
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--darkBlue);
}

::-webkit-scrollbar-thumb {
  background: var(--defaultRed);
  border-radius: 10px;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.mainContentWrappers {
  margin-left: 30vw;
  margin-right: 10vw;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .title{
    font-size:2.5rem;
  }

  .mainContentWrappers{
    margin-left: 10vw;
  }
}