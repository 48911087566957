h3{
  color: white;
  font-size:3em;
  font-family: var(--headerFont);
  /*margin-left: .5rem;*/
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  h3{
    margin-left:-.6em;
  }
}